#find-loads-ver-2-page {
  .find-loads-timeout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10%;
    text-align: center;

    >img {
      width: 80px;
      height: 80px;
    }
  }
}